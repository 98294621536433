import { AntDesignFilterFilled } from '@/assets/icons/funds/AntDesignFilterFilled';
import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseSift from '@/components/base/baseSift';
import { FeaturesDateTime } from '@/components/features/featuresDateTime';
import {
  CompletedOrderOrderProcessEnum,
  FundsOrderHistoryEnum, WalletTypeEnum,
} from '@/enums/businessEnum';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import useBusinessHooks from '@/hooks/useBusinessHooks';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import FeaturesFundingRecordsCard from '@/pages/funds/orderHistory/index/components/FeaturesFundingRecordsCard';
import OrderHistorySift from '@/pages/funds/orderHistory/index/components/OrderHistorySift';
import useConstants from '@/pages/funds/orderHistory/index/useConstants';
import { fundsListApi, fundsListNewApi } from '@/services/api/requestApi';
import { FormatUtils } from '@/utils/format';
import { history } from '@@/core/history';
import useUrlState from '@ahooksjs/use-url-state';
import { Button, Divider, useDisclosure } from '@nextui-org/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DirectionSelection from './DirectionSelection';

export default function FundingRecordsList({ sendFn, setLoadingDisplay }: any) {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { directionOption, contractSourceOption, coinSourceOption } = useConstants();
  const { getInfoArray } = useBusinessHooks();
  const [urlState] = useUrlState<any>({});
  const { walletType, coinId } = urlState || {};

  const [direction, setDirection] = useState(directionOption[0]);
  const [sourceType, setSourceType] = useState({
    text: t('全部'),
    value: undefined,
  });
  const [time, setTime] = useState<string[]>([]);
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  // 请求参数(依赖更新,分页hooks会自动发起请求)
  const params = useMemo(() => {
    return {
      sourceType: walletType === WalletTypeEnum.CONTRACT ? undefined : sourceType?.value, //操作类型
      fundType: sourceType?.value, //操作类型
      direction: direction?.value,
      startTime: time.length > 0 ? encodeURI(time[0] + 'T00:00:00') : undefined,
      endTime: time.length > 0 ? encodeURI(time[1] + 'T23:59:59') : undefined,
      walletType,
      coinId,
      sourceCoinId: coinId,
      ...pageParams,
    };
  }, [sourceType, time, walletType, coinId, pageParams, direction]);

  const apiFn = useCallback((data: any) => {
    if (![WalletTypeEnum.CONTRACT, WalletTypeEnum.COIN].includes(walletType)) {
      return fundsListApi(data);
    }
    return fundsListNewApi(data);
  }, [walletType]);

  const { list, loading, loadMore, error, total, ...fundingRecordsPageProps } =
    usePaginationPage({
      fn: apiFn,
      params,
      setParams: setPageParams,
    });
  // 更新监听
  useEffect(() => {
    if (setLoadingDisplay) {
      setLoadingDisplay?.(loading);
    }
  }, [loading]);

  return (
    <div>
      <div className="flex justify-between relative pb-4">
        <div className="flex">
          {/* <BaseSift
            options={directionOption}
            onChange={setDirection as any}
            current={direction}
            title={t('方向')}
            leftNode={()=>{

            }}
          /> */}
          <DirectionSelection
            options={directionOption}
            onChange={setDirection as any}
            current={direction}
            title={t('方向')}
          />
          {
            walletType === WalletTypeEnum.CONTRACT && <BaseSift
              options={contractSourceOption}
              onChange={setSourceType as any}
              current={sourceType}
              title={t('来源')}
            />
          }
          {
            walletType === WalletTypeEnum.COIN && <BaseSift
              options={coinSourceOption}
              onChange={setSourceType as any}
              current={sourceType}
              title={t('来源')}
            />
          }
          {
            ![WalletTypeEnum.CONTRACT, WalletTypeEnum.COIN].includes(walletType) && <OrderHistorySift
              title={t('来源')}
              onChange={(e: any) => {
                setSourceType(e);
              }}
              current={sourceType}
            />
          }
        </div>
        <AntDesignFilterFilled
          className="text-iconFontColor sm:cursor-pointer text-base absolute right-0"
          onClick={onOpen}
        />
      </div>
      {/*列表*/}
      <PullToRefresh
        loadMore={loadMore}
        total={total}
        list={list}
        error={error}
        loading={loading}
        loadingDisplay={false}
        {...fundingRecordsPageProps}
      >
        {list.map((item: any) => {
          return (
            <div key={item?.id}>
              <FeaturesFundingRecordsCard
                item={item}
                clickOnTheCard={(data) => {
                  // 手续费无详情
                  if (+data?.feeMark !== 0) return;
                  // 闪兑跳转
                  if (
                    data?.sourceType ===
                    FundsOrderHistoryEnum.ORDER_FLASH_EXCHANGE
                  ) {
                    history.push(
                      `${PageEnum.CONTRACT_ORDER_DETAIL}/${data?.sourceId}?type=flashExchange`,
                    );
                    return;
                  }
                  // 闪兑跳转
                  if (
                    data?.sourceType === FundsOrderHistoryEnum.CONTRACT_TRANSFER
                  ) {
                    history.push(
                      `${PageEnum.CONTRACT_ORDER_DETAIL}/${data?.id}?type=transfer`,
                    );
                    return;
                  }
                  // 币币订单
                  if (
                    [
                      FundsOrderHistoryEnum.CONTRACT_ORDER_TRADE,
                      FundsOrderHistoryEnum.CONTRACT_ORDER_TRADE_PRICE_LIMIT,
                      FundsOrderHistoryEnum.CONTRACT_TRANSFER,
                    ].includes(data?.sourceType)
                  ) {
                    history.push(
                      `${PageEnum.CONTRACT_ORDER_DETAIL}/${data?.orderInfo?.id}?type=coin`,
                    );
                    return;
                  }
                  // 通用资金模板
                  sessionStorage.setItem(
                    CacheEnum.APP_FUNDS_RECORD_DETAIL,
                    JSON.stringify(getInfoArray(item, true)),
                  );
                  history.push(
                    `${PageEnum.ORDER_DETAIL}?type=recordsOfFunds&targetUrl=${
                      location.pathname + location.search
                    }&noLoading=true`,
                  );
                }}
              >
                {sendFn && (
                  <>
                    <div className="flex justify-between mt-2 text-xs text-right">
                      <span className=" text-auxiliaryTextColor">
                        {t('订单号')}
                      </span>
                      <span className="text-auxiliaryTextColor flex items-center">
                        {item?.orderInfo?.no}{' '}
                        <PhCopyFill
                          onClick={() =>
                            FormatUtils.copyText(item?.orderInfo?.no)
                          }
                          className="text-base ml-1"
                        />
                      </span>
                    </div>
                    <Button
                      className="mainColorButton !h-[30px] !py-0 mt-2"
                      onClick={() => {
                        sendFn({
                          type: CompletedOrderOrderProcessEnum.ALL,
                          id: item?.id,
                        });
                      }}
                    >
                      {t('发送')}
                    </Button>
                    <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
                  </>
                )}
                <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
              </FeaturesFundingRecordsCard>
            </div>
          );
        })}
      </PullToRefresh>
      {/*时间弹窗*/}
      <FeaturesDateTime
        open={isOpen}
        onClose={onClose}
        onCancel={onClose}
        onConfirm={(value: any) => {
          const { start, end } = value;
          setTime([start, end]);
          onClose();
        }}
      />
    </div>
  );
}
