import BasePullDownToRefresh from '@/components/base/basePullDownToRefresh';
import BaseTopNav from '@/components/base/baseTopNav';
import { CompletedOrderOrderProcessEnum, WalletTypeEnum } from '@/enums/businessEnum';
import { useTranslation } from 'react-i18next';
import BaseTabs from '@/components/base/baseTabs';
import { useEffect, useState } from 'react';
import FundingRecordsList from '@/pages/funds/orderHistory/index/components/FundingRecordsList';
import useUrlState from '@ahooksjs/use-url-state';
import RechargeRecordList from '@/pages/funds/orderHistory/index/components/RechargeRecordList';
import WithdrawRecordList from '@/pages/funds/orderHistory/index/components/WithdrawRecordList';
import TransferRecordList from '@/pages/funds/orderHistory/index/components/TransferRecordList';
import FlashRedemptionRecordList from '@/pages/funds/orderHistory/index/components/FlashRedemptionRecordList';
import CacheList from '@/pages/funds/orderHistory/index/components/CacheList';
import CacheEnum from '@/enums/cacheEnum';
import { useMount, useUpdateEffect } from 'ahooks';
import { useModel } from '@@/exports';
import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';

export default function OrderHistoryView() {
  //获取国际化
  const { t } = useTranslation();
  const { preRouter } = useModel('routing');
  // console.log(preRouter,"preRouter");
  // 加载
  const [loadingDisplay, setLoadingDisplay] = useState(false);
  const [urlState] = useUrlState<any>({});
  const [pageKey, setPageKey] = useState(1);
  const {
    type,
    walletType,
  } = urlState || {};
  /*-----------------tab栏目和内容展示-----------------------------------*/
  const [fundingRecordsTabs] = useState([
    {
      text: t('全部'),
      value: CompletedOrderOrderProcessEnum.ALL,
      component: FundingRecordsList,
      props: {
        setLoadingDisplay,
      },
    },
    {
      text: t('充值'),
      value: CompletedOrderOrderProcessEnum.ADD,
      component: RechargeRecordList,
      props: {
        setLoadingDisplay,
      },
      hidden: +walletType === +WalletTypeEnum.COIN,
    },
    {
      text: t('提现'),
      value: CompletedOrderOrderProcessEnum.SUB,
      component: WithdrawRecordList,
      props: {
        setLoadingDisplay,
      },
      hidden: +walletType === +WalletTypeEnum.COIN,
    },
    {
      text: t('划转'),
      value: CompletedOrderOrderProcessEnum.TRANSFER,
      component: TransferRecordList,
      props: {
        setLoadingDisplay,
      },
    },
    {
      text: t('闪兑'),
      value: CompletedOrderOrderProcessEnum.SWAP,
      component: FlashRedemptionRecordList,
      hidden: +walletType === +WalletTypeEnum.CONTRACT,
      props: {
        setLoadingDisplay,
      },
    },
  ]?.filter((item) => !item.hidden));

  const [currentTab, setCurrentTab] = useState(type ?? CompletedOrderOrderProcessEnum.ALL);
  useUpdateEffect(() => {
    localStorage.setItem(CacheEnum.APP_FUNDS_RECORD_TAB, currentTab);
  }, [currentTab]);
  useEffect(() => {
    if (preRouter?.includes?.('/order/detail') || preRouter?.includes?.('/funds/tradingHistoryDetail')) {
      const cacheCurrentTab = localStorage.getItem(CacheEnum.APP_FUNDS_RECORD_TAB);
      if (cacheCurrentTab) {
        setCurrentTab(cacheCurrentTab);
      }
    }
  }, [preRouter]);

  return (
    <BasePullDownToRefresh
      refreshFn={() => {
        setPageKey(pageKey + 1);
      }}
    >
      <div className="w-full">
        <BaseTopNav title={t('记录')} rightNode={
          loadingDisplay && (
            <div className="flex items-center justify-center w-fit h-full mr-4">
              <SvgSpinnersBarsRotateFade />
            </div>
          )
        } />
        <div className="">
          <BaseTabs
            options={fundingRecordsTabs}
            tabsPropsProps={{
              classNames: {
                tabList: 'px-4',
              },
            }}
            value={currentTab}
            onChange={(cur) => {
              setCurrentTab(cur);
            }}
          />
        </div>
      </div>
      {/*这里使用display大法渲染当前tab对应的component--避免重复请求 影响体验*/}
      <div className="min-h-[40vh] px-4 mt-2" key={pageKey}>
        <CacheList currentTab={currentTab} tabs={fundingRecordsTabs} />
      </div>
    </BasePullDownToRefresh>
  );
}
